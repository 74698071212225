import request from '@/utils/request'
/**
 *    公司相关的接口 api
 */

// 获取公司列表
export const getCompanyListApi = params =>
  request({
    url: `/company/list`,
    method: 'get',
    params
  })
// 获取公司详情
export const getCompanyDetailApi = params =>
  request({
    url: `/company/detail/${params.companyId}`,
    method: 'get'
  })
export const getVipInfo = params =>
  request({
    url: `/company/${params.companyId}/getVipInfo`,
    method: 'get'
  })

// 公司案例排序
// export const companyCaseSortApi = params =>
//   request({
//     url: `/company/case/sort`,
//     method: 'put',
//     data: JSON.stringify(params)
//   })
// 拖拽修改案例排序数
export const companyCaseSortApi = params =>
  request({
    url: `/company/case/sort`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 新增案例
export const companyCaseCreateApi = params =>
  request({
    url: `/company/case/create`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 删除案例
export const companyCaseDeleteApi = params =>
  request({
    url: `/company/case/delete/${params.caseId}`,
    method: 'delete'
  })

// 获取案例详情
export const getCompanyCaseDetailApi = params =>
  request({
    url: `/company/case/detail/${params.caseId}`,
    method: 'get'
  })

// 公司入驻
export const companyEnterApi = params =>
  request({
    url: `/company/enter`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 入驻审核
export const companyCheckApi = params =>
  request({
    url: `/company/check`,
    method: 'put',
    data: JSON.stringify(params)
  })

// 查找公司管理员
export const getcompanyAdminApi = params =>
  request({
    url: `/companyAdmin/${params.userId}`,
    method: 'get'
  })
// 新增管理员
export const companyAdminAddApi = params =>
  request({
    url: `/companyAdmin/add`,
    method: 'post',
    data: JSON.stringify(params)
  })
